var cookieconsent;
window.addEventListener('load', function() {
    cookieconsent = initCookieConsent();
    cookieconsent.run({
        "autorun": true,
        "current_lang": "default",
        "page_scripts": true,
        "gui_options": {
            "consent_modal": {
                "layout": "cloud",
                "position": "bottom center",
                "transition": "slide"
            },
            "settings_modal": {
                "layout": "box",
                "transition": "slide"
            }
        },
        "languages": {
            "default": {
                "consent_modal": {
                    "title": "K poslechu audioknihy se 🍪 určitě hodí, proto je naleznete i na našem webu",
                    "description": "Cookies 🍪 nám zde umožňují zlepšit Váš zážitek z prohlížení našeho webu. Díky nim jsme schopni zjistit, která audiokniha se Vám líbí a jak směřovat náš obsah. Budeme moc rádi, když udělíte souhlas. Děkujeme !",
                    "primary_btn": {
                        "text": "Povolit všechny cookies",
                        "role": "accept_all"
                    },
                    "secondary_btn": {
                        "text": "Nastavení",
                        "role": "settings"
                    }
                },
                "settings_modal": {
                    "title": "Nastavení cookies",
                    "save_settings_btn": "Uložit",
                    "accept_all_btn": "Přijmout všechny cookies",
                    "reject_all_btn": "Odmítnout vše",
                    "blocks": [{
                        "title": "Co jsou cookies a kde je používáme?",
                        "description": "Použití cookies na naší stránce se neliší od jiných webů. Pomáhá nám k lepší funkcionalitě, získávání informací, co Vás na webu zajímá a díky tomu jsme schopni web vylepšovat k Vaší spokojenosti."
                    }, {
                        "title": "Nezbytné",
                        "description": "Nezbytné cookies potřebujeme pro to, aby web mohl správně fungovat.",
                        "toggle": {
                            "value": "necessary",
                            "enabled": true,
                            "readonly": true
                        }
                    }, {
                        "title": "Analytické",
                        "description": "Pomáhají nám zjistit, které audio knihy Vás baví a máte rádi.",
                        "toggle": {
                            "value": "analytics",
                            "enabled": false,
                            "readonly": false
                        }
                    }]
                }
            }
        }
    });
});